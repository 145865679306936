import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import { Select, defaults as defaultInteractions } from "ol/interaction.js";
import { click } from "ol/events/condition.js";
import { createStyleFunction } from "./style-function";
import { makeFeaturesFromEntity } from "../entity";
import { usePickSeatsStore } from "./store";
import { getSeatFeatureProps } from "./utils";

const width = 1920;
const height = 1080;
const extent = [0, -height, width, 0];

export const createMap = (target: string | HTMLElement) => {
  const store = usePickSeatsStore();

  const styleFunction = createStyleFunction();

  const layerSource = new VectorSource({ wrapX: false });

  const entitiesLayer = new VectorLayer({
    source: layerSource,
    style: styleFunction,
    extent,
  });

  const select = new Select({
    condition: click,
    toggleCondition: click,
    style: styleFunction,
    multi: true,
    filter(feature) {
      if (store.loading || !feature.get("seat")) {
        return false;
      }
      const { unavailable } = getSeatFeatureProps(feature, store);
      return !unavailable;
    },
  });

  const interactions = defaultInteractions({
    // mouseWheelZoom: false,
    // shiftDragZoom: false,
    // pinchRotate: false,
  });

  const map = new Map({
    layers: [entitiesLayer],
    interactions: interactions.extend([select]),
    view: new View({
      extent,
      maxZoom: 20,
    }),
  });

  const initMap = () => {
    map.setTarget(target);
    const view = map.getView();
    const eventMap = store.eventMap;

    view.setCenter(eventMap?.viewport?.center || [0, 0]);
    view.setZoom(eventMap?.viewport?.zoom || 10);

    if (eventMap?.entities) {
      for (const entity of eventMap.entities || []) {
        const features = makeFeaturesFromEntity(entity);
        layerSource.addFeatures(features);
      }
    }
  };

  return {
    map,
    layerSource,
    select,
    initMap,
  };
};
