import colors from "vuetify/lib/util/colors.mjs";
import type { FeatureLike } from "ol/Feature";
import type { Entity } from "../types";
import { Fill, RegularShape, Stroke, Style, Text } from "ol/style";
import type { StyleFunction } from "ol/style/Style";
import { type LineString, Point } from "ol/geom";
import { last } from "lodash";
import type { Coordinate } from "ol/coordinate";
import { getSeatFeatureProps } from "./utils";
import { isArrowShape, isText } from "../entity";
import { usePickSeatsStore } from "./store";

export function createStyleFunction(): StyleFunction {
  const store = usePickSeatsStore();
  const fill = new Fill({ color: "#FFFFFF" });
  const primaryColor = colors.blue.base;
  const secondaryColor = colors.indigo.lighten1;

  return function (feature: FeatureLike): Style | Style[] {
    const entity: Entity | undefined = feature.get("entity");

    if (!entity) {
      return [];
    }

    if (feature.get("seat")) {
      const { selected, unavailable } = getSeatFeatureProps(feature, store);
      const outLineColor = selected ? primaryColor : secondaryColor;

      const textFill = new Fill({
        color: selected || unavailable ? "#FFFFFF" : outLineColor,
      });

      const seatText = new Text({
        text: selected ? "✓" : String(feature.get("seat")),
        font: "italic 10px sans-serif",
        textAlign: "center",
        fill: textFill,
      });

      if (selected) {
        return new Style({
          fill: new Fill({ color: colors.indigo.base }),
          stroke: new Stroke({
            color: colors.indigo.darken3,
            width: 1,
          }),
          text: seatText,
        });
      }

      if (unavailable) {
        return new Style({
          fill: new Fill({ color: "#616161" }),
          stroke: new Stroke({
            color: secondaryColor,
            width: 1,
          }),
          text: seatText,
        });
      }

      return new Style({
        fill: new Fill({ color: "#b4b4b4" }),
        stroke: new Stroke({
          color: secondaryColor,
          width: 1,
        }),
        text: seatText,
      });
    }

    const text = new Text({
      text: entity.name,
      font: "italic 12px sans-serif",
      textAlign: "center",
      fill: new Fill({
        color: secondaryColor,
      }),
    });

    const stroke = new Stroke({
      color: secondaryColor,
      width: 2,
    });

    if (isArrowShape(entity)) {
      const styles = [new Style({ stroke })];

      const geometry = feature.getGeometry()! as LineString;
      const segments: [Coordinate, Coordinate][] = [];

      geometry.forEachSegment(function (start, end) {
        segments.push([start, end]);
      });

      // arrow
      const [start, end] = last(segments) as [Coordinate, Coordinate];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      styles.push(
        new Style({
          geometry: new Point(end),
          // image: new Icon({
          //   src: 'data/arrow.png',
          //   anchor: [0.75, 0.5],
          //   rotateWithView: true,
          //   rotation: -rotation,
          // }),
          image: new RegularShape({
            fill,
            points: 3,
            radius: 8,
            rotation: -rotation,
            angle: Math.PI / 2, // rotate 90°
          }),
        })
      );

      return styles;
    }

    if (isText(entity)) {
      //
    }

    return new Style({
      fill,
      stroke,
      text,
    });
  };
}
