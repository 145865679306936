import type { RoundTable } from "../types";
import type { FeatureLike } from "ol/Feature";
import { usePickSeatsStore } from "./store";

export const getRouteTable = (entity: RoundTable) => {
  const size = 300;
  const radius = entity.seats > 10 ? 50 : 35;
  const chairRadius = entity.seats > 10 ? 75 : 60;
  const center = size / 2;

  const chairs: {
    x: number;
    y: number;
    r: number;
  }[] = [];

  for (let i = 0; i < entity.seats; i++) {
    const angle = (Math.PI * 2 * i) / entity.seats;
    const x = chairRadius * Math.sin(angle) + center;
    const y = chairRadius * Math.cos(angle) + center;
    chairs.push({ x, y, r: 12 });
  }

  return {
    size,
    radius,
    chairRadius,
    center,
    chairs,
  };
};

export const getSeatFeatureProps = (
  feature: FeatureLike,
  store: ReturnType<typeof usePickSeatsStore>
) => {
  const seatId = feature.get("seatId");
  const selected = !store.loading && seatId && store.selection.includes(seatId);
  const unavailable = !store.loading && !store.availableSeats.includes(seatId);

  return {
    seatId,
    selected,
    unavailable,
  };
};
