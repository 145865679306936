<template>
  <div id="event-map" class="w-full h-full"></div>
</template>

<script lang="ts">
import { createMap } from "./map";
import { usePickSeatsStore } from "./store";

export default defineComponent({
  setup() {
    const { map, select, initMap } = createMap("event-map");
    const store = usePickSeatsStore();

    provide("map", map);
    provide("select", select);

    select.on("select", (event) => {
      store.handleSelect(event.selected, event.deselected);
    });

    onMounted(initMap);
  },
});
</script>
